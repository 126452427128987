import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import { Button } from "../components/ui/buttons"

const NotFoundPage = () => (
  <Page>
    <SEO title="Not found" />
    <div className="container">
      <h1>Whoops!</h1>
      <p>This page doesn't exist, sorry about that!</p>
      <Link to="/">
        <Button>Return home</Button>
      </Link>
    </div>
  </Page>
)

export default NotFoundPage

const Page = styled.div`
  a {
    text-decoration: none;
  }

  .container {
    width: 350px;
    height: calc(100vh - 630px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    p {
      margin: 2em 0;
    }
  }
`
