import styled, { css } from "styled-components"
import theme from "../../theme"

export const Button = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.4rem 1.2rem;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(to right, #f1b759 0%, #e88e3d 51%, #e78a3a 100%);
  color: #fff;

  &:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: none;
  }

  &:disabled {
    background: #757575;
  }

  ${(props) =>
    props.purple &&
    css`
      background: linear-gradient(135deg, #e404cb 0%, #fd4d73 100%);
      color: #fff;

      &:hover {
        filter: hue-rotate(10deg);
      }
    `}

  ${(props) =>
    props.medium &&
    css`
      padding: 0.4rem 1.4rem;
      font-size: 0.8rem;
    `}

  ${(props) =>
    props.large &&
    css`
      padding: 0.4rem 2rem;
      font-size: 1.1rem;
    `}

  ${(props) =>
    props.xlarge &&
    css`
      padding: 0.4rem 1.8rem;
      font-size: 1.5rem;
    `}
`

export const ButtonTransparent = styled.button`
  border: 1px solid ${theme.orange};
  font-weight: 200;
  background: none;
  border-radius: 5px;
  min-width: 135px;
  padding: 12px 0;
  font-size: 21px;
  color: ${theme.orange};
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${theme.orange};
    color: #fff;
    cursor: pointer;
  }

  @media only screen and (max-width: 865px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 10px 0;
    height: 40px;
    width: 120px;
  }
`

export const NewButton = styled.button`
  height: 50px;
  width: 250px;
  background: ${theme.orange};
  border: none;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* z-index: 9; */

  &:hover {
    cursor: pointer;
  }
`
